import React, { FC, useEffect, useRef } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import useSpaceAfterContent from '@/hooks/useSpaceAfterContent'
import { MAX_CONTENT_WIDTH } from '@/hooks/useSpaceAfterContent/constants'

export type DisplaySkyAdProps = {
    placement?: string
    placementId?: string
    visibleBreadCrumbs: boolean
    isLeftSky?: boolean
}

const DisplaySkyAd: FC<DisplaySkyAdProps> = ({
    placement,
    placementId,
    visibleBreadCrumbs,
    isLeftSky = false,
    ...rest
}) => {
    const ref = useRef<HTMLDivElement>(null)
    const loaded = useRef(false)
    const { spaceFromContentToEdge } = useSpaceAfterContent(326)

    useEffect(() => {
        if (ref.current && !loaded.current && placementId) {
            window.SDG?.cmd.push(function () {
                window.S1RegisteredSlots = window.S1RegisteredSlots || {}
                if (!window.S1RegisteredSlots[placementId]) {
                    window.S1RegisteredSlots[placementId] = true
                    window.SDG?.Publisher?.registerSlot?.(
                        placementId,
                        ref.current as HTMLDivElement
                    )?.load?.()
                } else {
                    // eslint-disable-next-line no-console
                    console.warn(
                        'Did not SDG.Publisher.registerSlot, was registered already',
                        placementId
                    )
                }
                loaded.current = true
            })
        }
    }, [placement, placementId])

    return (
        <NonFlexingContainer centerHorizontal testID={placement} {...rest}>
            <div
                dir={isLeftSky ? 'rtl' : 'ltr'}
                ref={ref}
                className={`s1-ad s1-ad-name-${placement} s1-ad-${placementId} text-center ${
                    visibleBreadCrumbs ? 'top-[152px]' : 'top-[128px]'
                }`}
                style={{
                    width: spaceFromContentToEdge,
                    [isLeftSky ? 'left' : 'right']:
                        (window.innerWidth - MAX_CONTENT_WIDTH) / 2 - spaceFromContentToEdge,
                    display: spaceFromContentToEdge <= 0 ? 'none' : 'block',
                }}
            >
                <div id={placementId}></div>
            </div>
        </NonFlexingContainer>
    )
}

export default DisplaySkyAd
