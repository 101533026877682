import React, { FC, memo, useCallback, useMemo, useRef } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import {
    ComponentType,
    LabelProps,
    LayoutComponentProps,
    LayoutProps,
    MoreListItemProps,
    TagProps,
} from '@sport1/types/web'
import { BurgerMenuHeader } from '@/components/BurgerMenu/BurgerMenuHeader'
import MoreList from '@/components/MoreList'
import PopularCategoryTags from '@/components/PopularCategoryTags'
import Search from '@/components/Search'
import TagList from '@/components/TagList'
import HorizontalTagList from '@/components/TagList/Horizontal'
import { isMobileView } from '@/helpers/pageHelper'
import { slugify } from '@/helpers/slugHelper'
import { getLayout } from '@/pages/api/cms/content/tag/[id]'
import { useTracking } from '@/utils/tracking/TrackingProvider'

type Props = {
    isOpen: boolean
    initialTag?: TagProps | LabelProps
    layoutData: LayoutProps
    toggleMenu: () => void
    closeMenu: () => void
    scrollToTop: () => void
}

const HOME_TAG_ID = '226'

const homeComponent: TagProps | LabelProps = {
    contextId: HOME_TAG_ID,
    tagId: 226,
    contextType: 'TAG',
    title: 'HOME',
    iconUrlType: 'ICON',
    iconUrl: 'https://reshape.sport1.de/c/t/F8D9D9DD-92A0-4EC7-B5B9-FB37AB838AF1/200x200',
    href: '/',
}

const BurgerMenuContent: FC<Props> = ({
    isOpen,
    initialTag,
    layoutData,
    toggleMenu,
    closeMenu,
    scrollToTop,
}) => {
    const [tags, setTags] = React.useState<(TagProps | LabelProps)[]>([])
    const layoutMap = useRef<Map<string, LayoutProps>>(new Map())
    const isFirstTagList = useRef(true)
    const { trackInteraction } = useTracking()
    const currentTag = tags[tags.length - 1] as TagProps | undefined

    const onIconPress = useCallback(() => {
        setTags([...tags.slice(0, -1)])
    }, [tags])

    const trackOnTagPress = useCallback(
        (tag: TagProps | LabelProps | MoreListItemProps) => {
            if (tag.urlType !== 'LAYOUT') {
                let interaction_label
                if (tags.length > 0) {
                    interaction_label = `menu_${slugify(tags[tags.length - 1].title)}_${slugify(
                        tag.title
                    )}`
                } else {
                    interaction_label = `menu_${slugify(tag.title)}`
                }
                trackInteraction({
                    devices: 'all',
                    interaction_category: 'navigation',
                    interaction_action: 'click',
                    interaction_label,
                    platforms: isMobileView() ? 'mobile' : 'desktop',
                })
            }
        },
        [tags, trackInteraction]
    )

    const openLayout = useCallback(
        (tag: TagProps | LabelProps) => {
            trackOnTagPress(tag)
            if (tag.urlType === 'LAYOUT' && tag.url && !tags.includes(tag)) {
                const tagId = `${(tag as TagProps).tagId}`
                if (!layoutMap.current.has(tagId)) {
                    getLayout(tag.url).then(currentLayout => {
                        layoutMap.current.set(tagId, currentLayout)
                        scrollToTop()
                        setTags([...tags, tag])
                    })
                } else {
                    scrollToTop()
                    setTags([...tags, tag])
                }
            } else {
                toggleMenu()
            }
        },
        [trackOnTagPress, tags, scrollToTop, toggleMenu]
    )

    const renderItem = useCallback(
        (component: LayoutComponentProps) => {
            switch (component.type) {
                case ComponentType.TAG_LIST:
                    if (component.positioning === 'horizontal') {
                        return <HorizontalTagList componentData={component} />
                    }
                    const isFirstLayer = isFirstTagList.current && tags.length <= 0
                    if (isFirstLayer && component.content[0].contextId !== HOME_TAG_ID) {
                        component.content.unshift(homeComponent)
                    }
                    isFirstTagList.current = false
                    return (
                        <TagList
                            {...component}
                            bold={isFirstLayer}
                            onTagPress={openLayout}
                            currentTag={currentTag}
                        />
                    )
                case ComponentType.MORE_LIST:
                    return <MoreList items={component.content} onTagPress={trackOnTagPress} />
                case ComponentType.POPULAR_CATEGORY_TAGS:
                    return <PopularCategoryTags {...component} onTagPress={openLayout} />
                case ComponentType.TAG_SEARCH_INPUT:
                    return (
                        <NonFlexingContainer
                            marginX="spacing-7"
                            marginBottom="spacing-2"
                            display={['flex', 'flex', 'none']}
                        >
                            <Search {...component} toggleMenu={toggleMenu} />
                        </NonFlexingContainer>
                    )
            }
        },
        [openLayout, tags.length, trackOnTagPress, currentTag, toggleMenu]
    )

    React.useEffect(() => {
        if (initialTag && !tags.includes(initialTag)) {
            openLayout(initialTag)
        }
    }, [initialTag, tags, openLayout])

    React.useEffect(() => {
        if (!isOpen) {
            setTags([])
        }
    }, [isOpen])

    const stringifiedTagId = `${currentTag?.tagId}`
    const layout = layoutMap.current.get(stringifiedTagId) || layoutData
    isFirstTagList.current = true

    const componentWithoutSearchInput = useMemo(() => {
        return layout.components.filter(
            component => component.type !== ComponentType.TAG_SEARCH_INPUT
        )
    }, [layout.components])

    return (
        <>
            <BurgerMenuHeader
                title={currentTag?.title}
                onBack={tags.length > 0 ? onIconPress : undefined}
                onClose={closeMenu}
            />
            {componentWithoutSearchInput.map((component, index) => (
                <NonFlexingContainer
                    key={`${component.type}_${index}`}
                    spaceBottom="standard"
                    backgroundColor="pearl"
                >
                    {renderItem(component)}
                </NonFlexingContainer>
            ))}
        </>
    )
}

export default memo(BurgerMenuContent)
